import React, { useEffect, useState } from 'react';
import { Redirect } from 'react-router';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import InputMask from 'react-input-mask';
import { parse, differenceInYears, isValid } from 'date-fns';

import { getCategoriesTopics } from '../../actions/topics';
import { registerUser, validateUserExist } from '../../actions/auth';

import Seo from '../SEO';
import Terms from '../Popus/Terms';
import Privacy from '../Popus/Privacy';
import { validate, format } from 'rut.js';
import {
    isValidPhone,
    isValidEmail,
    isValidDate,
    CodeResponse,
} from '../../utils/helpers';

import RutPopup from '../Popus/Rut';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons/';

function validateLimit(value) {
    const numberPart = value.split('-')[0].replace(/\./g, '');
    if (parseInt(numberPart, 10) > 50000000) return false;
    return true;
}

export default function Register() {
    const dispatch = useDispatch();
    const { register, errors, getValues, handleSubmit, setValue, trigger } = useForm({
        mode: 'onBlur',
    });
    const [termsOpen, setTermsOpen] = useState(false);
    const [privacyOpen, setPrivacyOpen] = useState(false);
    const [password, setPassword] = useState('');
    const [validPassword , setValidPassword] = useState(false);
    const [repeatPassword, setRepeatPassword] = useState(false);

    // VALIDAR SI EXISTE RUT
    const [modalRut, showModalRut] = useState(false);
    const handleRut = (e) => {
        let rut = e.currentTarget;
        if (validate(rut.value)) {
            dispatch(
                validateUserExist({
                    dni: rut.value,
                })
            );
        }
    };
    const today = new Date();
    const onSubmitRegister = (data = {}) => {
        if (Object.keys(data).length > 0) {
            const {
                firstname = '',
                lastname = '',
                email = '',
                password = '',
                validate = 'email',
                category_topic: categories = [],
                dni = '',
                birthdate = '',
                specialty = '',
                purchaseFrequency = '',
                //phone = "",
                mobile = '',
            } = data;

            const dataAdditional = [];
            dataAdditional.push({
                type: 'dni',
                data: dni,
            });
            dataAdditional.push({
                type: 'datebirth',
                data: birthdate,
            });
            dataAdditional.push({
                type: 'specialty',
                data: specialty,
            });
            dataAdditional.push({
                type: 'mobile',
                data: mobile,
            });
            dataAdditional.push({
                type: 'purchaseFrequency',
                data: purchaseFrequency,
            });
/*
dataAdditional.push({
type: "phone",
data: phone,
});
*/
            if (Array.isArray(categories) && categories.length > 0) {
                dataAdditional.push({
                    type: 'categories',
                    data: categories.join(',') || '',
                });
            }
            dispatch(
                registerUser({
                    firstname,
                    lastname,
                    email,
                    password,
                    validate,
                    data: dataAdditional,
                })
            );
        }
    };

    useEffect(() => {
        dispatch(getCategoriesTopics());
    }, [dispatch]);

// const categories = useSelector((state) => state.categories_topics);
// const { data: dataCategoriesTopic = {}, isFetching = true } =
//   categories || {};
// const { topics_category: categoriesTopic = [] } = dataCategoriesTopic || {};

 

   const isValidPassword = (value) => {
    return value.length >= 8
      && /\d/.test(value)
      && /[A-Z]/.test(value)
      && /[a-z]/.test(value);
   }
  const validatePassword = (value) => {
    return{
        length: password.length >= 8,
            hasNumber: /\d/.test(password),
            hasUpperCase: /[A-Z]/.test(password),
            hasLowerCase: /[a-z]/.test(password),
    }
  };
  const handleRepeatPasswordInputChange = (e) => {
    const value = e.target.value;
    const scriptRegex = /script/i;
    const htmlRegex = /html/i;

    if (scriptRegex.test(value) || htmlRegex.test(value)) {
        e.target.value = '';  // Borra solo el campo de contraseña repetida
        setRepeatPassword(''); // Restablece solo el estado de la contraseña repetida
    } else {
        setRepeatPassword(value);  // Actualiza el estado de la contraseña repetida
    }
};



  const handleEmailInputChange = (e) => {
    const { name, value } = e.target;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    const scriptRegex = /script/i;
    const htmlRegex = /html/i;

    if (scriptRegex.test(value) || htmlRegex.test(value)) {
        e.target.value = '';
        return;
    }

    if (emailRegex.test(value)) {
        setValue(name, value);
        trigger(name);
    } else {
        e.target.value = getValues(name) || '';
    }
};


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const regex = /^[A-Za-zÀ-ÿ\s'-]*$/;
    const scriptRegex = /script/i;
    const htmlRegex = /html/i;

    const sanitizeInput = (input) => {
        return input
            .replace(/</g, "&lt;")
            .replace(/>/g, "&gt;")
            .replace(/"/g, "&quot;")
            .replace(/'/g, "&#39;");
    };

    if (scriptRegex.test(value) || htmlRegex.test(value)) {
        e.target.value = '';
        return;
    }

    if (regex.test(value)) {
        const sanitizedValue = sanitizeInput(value);
        setValue(name, sanitizedValue);
        trigger(name);
    } else {
        e.target.value = getValues(name) || '';
    }
};

const passwordCriteria = validatePassword(password);

const CustomSVG = ({ color }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="15" height="12" viewBox="0 0 15 12" fill="none">
        <path 
            d="M5.09473 11.7197L0.219727 6.50956C-0.0732422 6.19645 -0.0732422 5.68921 0.219727 5.3761L1.28027 4.24264C1.57324 3.92953 2.04785 3.92953 2.34082 4.24264L5.625 7.75261L12.6592 0.234833C12.9521 -0.0782775 13.4268 -0.0782775 13.7197 0.234833L14.7803 1.36829C15.0732 1.6814 15.0732 2.18864 14.7803 2.50175L6.15527 11.7197C5.8623 12.0328 5.3877 12.0328 5.09473 11.7197Z" 
            fill={color} 
        />
    </svg>
);

    const registerDataUser = useSelector((state) => state.register);
    const {
        data: dataRegister = {},
        err: errRegister = {},
        isFetching: isFetchingRegister = true,
        validateUser = false,
    } = registerDataUser || {};
    const { code: codeErrRegister = '' } = errRegister || {};
    const {
        isFetching: isFetchingValidate = true,
        data: dataValidateUser = false,
    } = validateUser || {};

    useEffect(() => {
        if (dataValidateUser) {
            showModalRut(true);
            setValue('dni', '', { shouldValidate: true, shouldDirty: true });
        }
    }, [dataValidateUser, setValue]);

    return (
        <>
            <Seo
                title='Inscripción'
                description='Inscríbete al club de beneficios de Mundo Experto'
                url='/inscripcion'
            />
            <section className='registro'>
                <div className='registro__bg'>
                    <img src='assets/img/registro-bg.jpg' alt='Inscripción' />
                </div>
                <div className='registro__container container'>
                    <div className='registro__form'>
                        <form
                            className='form registro__form__content'
                            onSubmit={handleSubmit(onSubmitRegister)}
                        >
                            <h2 className='color--primario section__title h1 text-upper text-wide'>
                                Inscripción
                            </h2>
                            <p className='color--primario section__subtitle'>
                                Escribe tus datos de seguridad para entrar a tu
                                sesión.
                            </p>
                            <div className='separador--big'></div>
                            <fieldset className='form__fieldset'>
                                <div className='row'>
                                    <div className='col-xs-12 col-md-6 col-lg-4'>
                                        <div className='form__grupo'>
                                            {errors.firstname ? (
                                                <span className='color--error'>
                                                    {errors.firstname.message}
                                                </span>
                                            ) : (
                                                <span style={{ color: '#3F3F3F' }}>*Este campo es obligatorio</span>
                                            )}

                                            <input
                                                className='text'
                                                style={{
                                                    color: '#D9D9D9 !important',
                                                    borderColor: errors.firstname ? '#E00109' : '',
                                                }}
                                                id='firstname'
                                                name='firstname'
                                                placeholder='Nombre'
                                                type='text'
                                                maxLength={24}
                                                { ...register('firstname', {
                                                required: '*Este campo es obligatorio',
                                                minLength: {
                                                value: 2,
                                                message: 'Debe tener al menos 2 caracteres',
                                                },
                                                maxLength: {
                                                value: 24,
                                                message: 'El campo solo debe de tener un máximo de 24 caracteres',
                                                },
                                                pattern: {
                                                value: /^[A-Za-zÀ-ÿ\s'-]*$/,
                                                message: 'No se permiten numeros ni simbolos',
                                            },
                                            })}
                                                onChange={handleInputChange}
                                            />
                                            <label htmlFor='firstname'>
                                                NOMBRE*
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-xs-12 col-md-6 col-lg-4'>
                                        <div className='form__grupo'>
                                            {errors.lastname ? (
                                                <span className='color--error'>
                                                    {errors.lastname.message}
                                                </span>
                                            ): (
                                                <span style={{ color: '#3F3F3F' }}>*Este campo es obligatorio</span>
                                            )}
                                            <input
                                                className='text'
                                                style={{
                                                    borderColor: errors.lastname? '#E00109' : '',
                                                }}
                                                id='lastname'
                                                name='lastname'
                                                maxLength={40}
                                                placeholder='Apellido Apellido'
                                                {...register('lastname', {
                                                required: '*Este campo es obligatorio',
                                                minLength: {
                                                value: 2,
                                                message: 'Debe tener al menos 2 caracteres',
                                                },
                                                maxLength: {
                                                value: 40,
                                                message: 'El campo solo debe de tener un máximo de 40 caracteres',
                                                },
                                                pattern: {
                                                value: /^[A-Za-zÀ-ÿ\s'-]*$/,
                                                message: 'No se permiten numeros ni simbolos',
                                                },
                                                })}
                                                onChange={handleInputChange}
                                            />
                                            <label htmlFor='lastname'>
                                                 APELLIDOS*
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-xs-12 col-md-6 col-lg-4'>
                                        <div className='form__grupo'>
                                            {errors.birthdate ? (
                                                <span className='color--error'>
                                                    {errors.birthdate.message}
                                                </span>
                                            ):(
                                                <span style={{color: '#3f3f3f'}}>*Este campo es obligatorio </span>
                                            )}
                                            <InputMask
                                                className='text'
                                                style={{
                                                    borderColor: errors.birthdate ? '#E00109' : '',
                                                }}
                                                id='birthdate'
                                                name='birthdate'
                                                mask='99/99/9999'
                                                placeholder='DD/MM/AA'
                                                ref={register({
                                                    required:
                                                        'El campo es requerido',
                                                    validate: {
                                                        validateDate: (value) =>
                                                            isValidDate(
                                                                value
                                                            ) ||
                                                            'La fecha es inválida',
                                                        validateBirthdate: (
                                                            value
                                                        ) => {
                                                            const birthdate =
                                                                parse(
                                                                    value,
                                                                    'dd/MM/yyyy',
                                                                    new Date()
                                                                );

                                                            if (
                                                                !isValid(
                                                                    birthdate
                                                                )
                                                            ) {
                                                                return 'La fecha es inválida';
                                                            }

                                                            const age =
                                                                differenceInYears(
                                                                    today,
                                                                    birthdate
                                                                );

                                                            if (
                                                                age < 18 ||
                                                                age >= 99
                                                            ) {
                                                                return 'La edad debe estar entre 18 y 98 años';
                                                            }

                                                            return true; // La validación es exitosa
                                                        },
                                                    },
                                                })}
                                            />
                                            <label htmlFor='birthdate'>
                                                    FECHA DE NACIMIENTO*
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-xs-12 col-md-6 col-lg-4'>
                                        <div className='form__grupo'>
                                            {errors.dni ?
                                                !isFetchingValidate && (
                                                    <span className='color--error'>
                                                        {errors.dni.message}
                                                    </span>
                                                ): (
                                                    <span style={{ color: '#3F3F3F' }}>*Este campo es obligatorio</span>
                                                )
                                                }
                                            {isFetchingValidate && (
                                                <span className='color--terciario'>
                                                    Validando{' '}
                                                    <FontAwesomeIcon
                                                        icon={faSpinner}
                                                        className='fa-pulse'
                                                    />
                                                </span>
                                            )}
                                            <input
                                                className='text'
                                                style={{

                                                    borderColor: errors.dni ? '#E00109' : '',
                                                }}
                                                id='dni'
                                                name='dni'
                                                placeholder='11.111.111-1'
                                                maxLength='13'
                                                onKeyUp={(e) =>
                                                    (e.target.value = format(
                                                        e.target.value
                                                    ))
                                                }
                                                onBlur={handleRut}
                                                ref={register({
                                                    required:
                                                        '*Este campo es obligatorio',
                                                    maxLength: {
                                                        value: 12,
                                                        message:
                                                            'El RUT es inválido',
                                                    },
                                                    validate: {
                                                        validateDNI: (
                                                            value
                                                        ) => {
                                                            const limitCheck =
                                                                validateLimit(
                                                                    value
                                                                );

                                                            if (!limitCheck) {
                                                                return 'Servicio disponible solo para personas naturales';
                                                            }

                                                            const originalCheck =
                                                                validate(value);

                                                            if (!originalCheck)
                                                                return 'El RUT es inválido';

                                                            return true;
                                                        },
                                                    },
                                                })}
                                            />
                                            <label htmlFor='dni'> RUT*</label>
                                        </div>
                                        {modalRut && (
                                            <RutPopup
                                                open={modalRut}
                                                setOpen={showModalRut}
                                            />
                                        )}
                                    </div>

                                 

                                    <div className='col-xs-12 col-md-6 col-lg-4'>
                                        <div className='form__grupo'>
                                            {errors.purchaseFrequency && (
                                                <span className='alerta alerta--error alerta--xsmall'>
                                                    {errors.purchaseFrequency.message}
                                                </span>
                                            )}
                                            <div className='icono'>
                                                <FontAwesomeIcon
                                                    icon={faChevronDown}
                                                />
                                            </div>
                                            <select
                                                name='purchaseFrequency'
                                                id='purchaseFrequency'
                                                ref={register({
                                                    required:
                                                        'El campo es requerido',
                                                })}
                                            >
                                                <option value='' hidden>
                                                    Seleccionar
                                                </option>
                                                <option value='semanal'>
                                                    Semanal
                                                </option>
                                                <option value='quincenal'>
                                                    Quincenal
                                                </option>
                                                <option value='mensual'>
                                                    Mensual
                                                </option>
                                                <option value='cada3Meses'>
                                                    Cada 3 meses
                                                </option>
                                                <option value='cada6MesesOMas'>
                                                    Cada 6 meses o más
                                                </option>
                                            </select>
                                            <label htmlFor='purchaseFrequency'>
                                                 FRECUENCIA DE COMPRA
                                            </label>
                                        </div>
                                    </div>

                                    <div className='col-xs-12 col-md-6 col-lg-4'>
                                        <div className='form__grupo'>
                                            {errors.specialty && (
                                                <span className='alerta alerta--error alerta--xsmall'>
                                                    {errors.specialty.message}
                                                </span>
                                            )}
                                            <div className='icono'>
                                                <FontAwesomeIcon
                                                    icon={faChevronDown}
                                                />
                                            </div>
                                            <select
                                                name='specialty'
                                                id='specialty'
                                                ref={register({
                                                    required:
                                                        'El campo es requerido',
                                                })}
                                            >
                                                <option value='' hidden>
                                                    Seleccionar
                                                </option>
                                                <option value='pintor'>
                                                    Pintor
                                                </option>
                                                <option value='constructor'>
                                                    Constructor
                                                </option>
                                                <option value='ingeniero'>
                                                    Ingeniero
                                                </option>
                                                <option value='arquitecto'>
                                                    Arquitecto
                                                </option>
                                                <option value='paisajista'>
                                                    Paisajista
                                                </option>
                                                <option value='ceramista'>
                                                    Ceramista
                                                </option>
                                                <option value='jardinero'>
                                                    Jardinero
                                                </option>
                                                <option value='alabanil'>
                                                    Albañil
                                                </option>
                                                <option value='carpintero'>
                                                    Carpintero
                                                </option>
                                                <option value='electricista'>
                                                    Electricista
                                                </option>
                                                <option value='gasfiter'>
                                                    Gasfíter
                                                </option>
                                                <option value='otro'>
                                                    Otro
                                                </option>
                                            </select>
                                            <label htmlFor='specialty'>
                                               ESPECIALIDAD
                                            </label>
                                        </div>
                                    </div>

                                    <div className='col-xs-12 col-md-6 col-lg-4'>
                                        <div className='form__grupo '>
                                            {errors.mobile ? (
                                                <span className='color--error'>
                                                    {errors.mobile.message}
                                                </span>
                                            ) : (
                                                <span style={{color: '#3f3f3f'}}>*Este campo es obligatorio </span>
                                            )}
                                            <InputMask
                                                className='text'
                                                style={{
                                                    borderColor: errors.mobile ? '#E00109' : '',
                                                }}
                                                id='mobile'
                                                name='mobile'
                                                placeholder='+56 9 1234 5678'
                                                mask='+56999999999'
                                                ref={register({
                                                    required:
                                                        'El campo es requerido',
                                                    validate: {
                                                        validateMobile: (
                                                            value
                                                        ) =>
                                                            isValidPhone(
                                                                value
                                                            ) ||
                                                            'El teléfono es inválido',
                                                    },
                                                })}
                                            />
                                            <label htmlFor='mobile'>
                                                Teléfono*
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-xs-12 col-md-6 col-lg-4'>
                                        <div className='form__grupo'>
                                            {errors.email ?(
                                                <span className='color--error'>
                                                    {errors.email.message}
                                                </span>
                                            ) : (
                                                <span style={{color: '#3f3f3f'}}>*Este campo es obligatorio </span>
                                            )}
                                            <input
                                                className='text'
                                                style={{
                                                    borderColor: errors.email ? '#E00109' : '',
                                                }}
                                                id='email'
                                                type='text'
                                                name='email'
                                                placeholder='nombre.apellido@email.cl'
                                                maxLength={64}
                                                ref={register({
                                                    required:
                                                        'El campo es requerido',
                                                    validate: {
                                                        validateEmail: (
                                                            value
                                                        ) =>
                                                            isValidEmail(
                                                                value
                                                            ) ||
                                                            'El email es inválido',
                                                    },
                                                })}
                                                onChange={handleEmailInputChange} 
                                            />
                                            <label htmlFor='email'>Email*</label>
                                        </div>
                                    </div>
                                    <div className='col-xs-12 col-md-6 col-lg-4'></div>
                                    <div className='col-xs-12 col-md-6 col-lg-4'>
                                        <div className='form__grupo'>
                                            {errors.password ? (
                                                <span className='color--error'>
                                                    {errors.password.message}
                                                </span>
                                            ):(
                                                <span style={{color: '#3f3f3f'}}>*Este campo es obligatorio </span>
                                            )}
                                            <input
                                                className='text'
                                                style={{
                                                    borderColor: errors.password ? '#E00109' : '',
                                                }}
                                                id='password'
                                                type='password'
                                                name='password'
                                                placeholder='•••••••••'
                                                maxLength={32}
                                                value={password}
                                                onChange={(e) => {
                                                const value = e.target.value;
                                                const scriptRegex = /script/i;
                                                const htmlRegex = /html/i;

                                                if (scriptRegex.test(value) || htmlRegex.test(value)) {
                                                e.target.value = '';
                                                setPassword('')
                                                } else {
                                                setPassword(value);
                                                }
                                                        }}
                                                ref={register({
                                                        required: 'El campo es requerido',
                                                        validate: {
                                                        validatePassword: (value) => {
                                                        const isValid = isValidPassword(value);
                                                        setValidPassword(isValid);
                                                        if (!isValid) {
                                                        return 'La contraseña debe tener al menos 8 caracteres, una letra mayúscula y un número';
                                                            }
                                                            return true; // Or `undefined` if you prefer
        },
    },
})}

                                            />
                                            <label htmlFor='password'>
                                                Contraseña*
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-xs-12 col-md-6 col-lg-4'>
                                        <div className='form__grupo'>
                                            {errors.repeat_password ? (
                                                <span className='color--error'>
                                                    {
                                                        errors.repeat_password
                                                            .message
                                                    }
                                                </span>
                                            ) : (
                                                <span style={{color: '#3f3f3f'}}>*Este campo es obligatorio </span>
                                            )}
                                            <input
                                                className='text'
                                                style={{
                                                    borderColor: errors.repeat_password ? '#E00109' : '',
                                                }}
                                                id='repeat_password'
                                                type='password'
                                                name='repeat_password'
                                                maxLength={32}
                                                placeholder='•••••••••'
                                                value={repeatPassword}
                                                onChange={handleRepeatPasswordInputChange}
                                                ref={register({
                                                required: 'El campo es requerido',
                                                validate: {
                                                matchesPreviousPassword: (value) => {
                                                const { password } = getValues(); // Obtiene el valor de la contraseña original
                                                const scriptRegex = /script/i;
                                                const htmlRegex = /html/i;

                                                // Verifica si contiene 'script' o 'html' y lo invalida
                                                if (scriptRegex.test(value) || htmlRegex.test(value)) {
                                                return 'Contraseña no válida: no puede contener "script" o "html"';
                                                }

                                                 // Valida si coincide con la contraseña original
                                                return password === value || 'Las contraseñas ingresadas no coinciden';
                                            },
                                         },
    })}
                                            />
                                            <label htmlFor='repeat_password'>
                                                Repetir Contraseña*
                                            </label>
                                        </div>
                                    </div>
                                    <div className='col-xs-12'>
                                                <div className=''>
                                                <p  className='text bold mb-3 text-dark'> Tu contraseña debe contener: </p>
                <ul className='lista'>
                    <li className='lista__item'>
                        <CustomSVG color={passwordCriteria.length ? "#FF9200" : "#D9D9D9"} />
                        <p>Mínimo 8 caracteres</p>
                    </li>
                    <li className='lista__item'>
                        <CustomSVG color={passwordCriteria.hasNumber ? "#FF9200" : "#D9D9D9"} />
                        <p> Contiene al menos un número </p>
                    </li>
                    <li className='lista__item'>
                        <CustomSVG color={passwordCriteria.hasUpperCase ? "#FF9200" : "#D9D9D9"} />
                        <p> Contiene al menos una mayúscula </p> 
                    </li>
                    <li className='lista__item'>
                        <CustomSVG color={passwordCriteria.hasLowerCase ? "#FF9200" : "#D9D9D9"} />
                        <p> Contiene al menos una minúscula</p> 
                    </li>
                </ul>
                
                                        </div>
                                    </div>
                                    <div className='col-xs-12'>
                                        <div className='form__checkbox'>
                                            <input
                                                id='terms'
                                                type='checkbox'
                                                name='terms'
                                                value='1'
                                                onClick={() =>
                                                    setTermsOpen(!termsOpen)
                                                }
                                                ref={register({
                                                    required:
                                                        'El campo es requerido',
                                                })}
                                            />
                                            <div className='form__checkbox__item'></div>
                                            <label htmlFor='terms'>
                                                Acepto los{' '}
                                                <span className='link-externo'>
                                                    términos y condiciones
                                                </span>
                                            </label>
                                        </div>
                                        {termsOpen && (
                                            <Terms
                                                open={termsOpen}
                                                setOpen={setTermsOpen}
                                            />
                                        )}
                                        {errors.terms && (
                                            <span className='color--error'>
                                                {errors.terms.message}
                                            </span>
                                        )}
                                    </div>
                                    <div className='col-xs-12'>
                                        <div className='separador'></div>
                                        <div className='form__checkbox'>
                                            <input
                                                id='politicas'
                                                type='checkbox'
                                                name='politicas'
                                                value='1'
                                                onClick={() => {
                                                    setPrivacyOpen(
                                                        !privacyOpen
                                                    );
                                                }}
                                                ref={register({
                                                    required:
                                                        'El campo es requerido',
                                                })}
                                            />
                                            <div className='form__checkbox__item'></div>
                                            <label htmlFor='politicas'>
                                                Acepto las{' '}
                                                <span className='link-externo'>
                                                    políticas de privacidad
                                                </span>
                                            </label>
                                        </div>
                                        {privacyOpen && (
                                            <Privacy
                                                open={privacyOpen}
                                                setOpen={setPrivacyOpen}
                                            />
                                        )}
                                        {errors.terms && (
                                            <span className='color--error'>
                                                {errors.terms.message}
                                            </span>
                                        )}
                                    </div>
                                    <div className='col-xs-12'>
                                        <div className='separador--big'></div>
                                        {isFetchingRegister && (
                                            <div className='preloader'>
                                                <div className='preloader__child'></div>
                                            </div>
                                        )}
                                        {codeErrRegister && (
                                            <div>
                                                {CodeResponse(codeErrRegister)}
                                            </div>
                                        )}
                                        {Object.keys(dataRegister).length >
                                            0 && (
                                            <Redirect to='/inscripcion/exito' />
                                        )}
                                        {((!isFetchingRegister &&
                                            Object.keys(dataRegister).length <
                                                1) ||
                                            codeErrRegister) && (
                                            <button disabled={!validPassword}
                                                type='submit'
                                                className='btn btn--small btn--terciario btn--invertido btn--redondo'
                                            >
                                                Registrarme
                                            </button>
                                        )}
                                    </div>
                                </div>
                                <div className='separador'></div>
                            </fieldset>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
}
